import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-table-name-column" }
const _hoisted_2 = {
  class: "app-table-name-column__label",
  "data-testid": "app-table-name-column-label"
}

import ProfileAvatar from '@/components/profile/ProfileAvatar/ProfileAvatar.vue';
import { getFullNameInitials } from '@vaibe-github-enterprise/kd-kse-fe-commons';

interface Props {
  name: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppTableNameColumn',
  props: {
    name: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(ProfileAvatar, {
      size: "32px",
      "label-font-size": "12px",
      "name-initials": _unref(getFullNameInitials)(props.name)
    }, null, 8, ["name-initials"]),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.name), 1)
  ]))
}
}

})