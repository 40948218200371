import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-sidebar-view-grid" }

interface Props {
  minLabelColumnWidth?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppSidebarViewGrid',
  props: {
    minLabelColumnWidth: { default: 'max-content' }
  },
  setup(__props: any) {

_useCssVars(_ctx => ({
  "2e8bb305": (_ctx.minLabelColumnWidth)
}))



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default")
  ]))
}
}

})