import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-sidebar-view-grid-row__label" }
const _hoisted_2 = { "data-testid": "app-sidebar-view-grid-row-label" }
const _hoisted_3 = { class: "app-sidebar-view-grid-row__value" }
const _hoisted_4 = {
  class: "app-sidebar-view-grid-row__value__label",
  "data-testid": "app-sidebar-view-grid-row-value"
}

interface Props {
  label: string;
  value?: string | number;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppSidebarViewGridRow',
  props: {
    label: {},
    value: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("span", _hoisted_1, [
      _createElementVNode("b", _hoisted_2, _toDisplayString(_ctx.label), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.value), 1)
      ])
    ])
  ], 64))
}
}

})