import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 0,
  class: "app-sidebar-menu-item__icon"
}
const _hoisted_3 = { class: "app-sidebar-menu-item__right-col" }
const _hoisted_4 = { class: "app-sidebar-menu-item__label" }
const _hoisted_5 = {
  key: 0,
  class: "app-sidebar-menu-item__description"
}

interface Props {
  label: string;
  description?: string;
  disabled?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppSidebarMenuItem',
  props: {
    label: {},
    description: {},
    disabled: { type: Boolean }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    disabled: _ctx.disabled,
    class: "app-sidebar-menu-item"
  }, [
    (_ctx.$slots['icon'])
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
          _renderSlot(_ctx.$slots, "icon")
        ]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default"),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.label), 1),
      (_ctx.description)
        ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.description), 1))
        : _createCommentVNode("", true)
    ])
  ], 8, _hoisted_1))
}
}

})