import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

import IconVaibeLogoSmallFilled from '@/assets/icons/icon-vaibe-logo-small-filled.vue';
import IconVaibeLogo from '@/assets/icons/icon-vaibe-logo.vue';

interface Props {
  isSmall: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppVaibeLogo',
  props: {
    isSmall: { type: Boolean }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, { to: "/" }, {
    default: _withCtx(() => [
      (_ctx.isSmall)
        ? (_openBlock(), _createBlock(IconVaibeLogoSmallFilled, {
            key: 0,
            alt: "Vaibe",
            "data-testid": "app-logo-small",
            class: "app-vaibe-logo__small"
          }))
        : (_openBlock(), _createBlock(IconVaibeLogo, {
            key: 1,
            alt: "Vaibe",
            "data-testid": "app-logo",
            class: "app-vaibe-logo"
          }))
    ]),
    _: 1
  }))
}
}

})