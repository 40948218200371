import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "app-breadcrumbs" }
const _hoisted_2 = {
  key: 0,
  class: "app-breadcrumbs__trailing"
}

import { computed, useSlots } from 'vue';
import { useI18n } from 'vue-i18n';
import { RouteRecordName, useRoute } from 'vue-router';
import AppIcon from '@/components/app/AppIcon/AppIcon.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'AppBreadcrumbs',
  setup(__props) {

const { t } = useI18n();

const route = useRoute();

const slots = useSlots();

const matchedRouteNames = computed<(RouteRecordName | undefined)[]>(() =>
  route.matched
    .filter((match) => !match.meta.default)
    .map((match) => match.name),
);

function isNotLastRoute(routeName?: RouteRecordName) {
  return (
    routeName !== matchedRouteNames.value[matchedRouteNames.value.length - 1]
  );
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(matchedRouteNames.value, (routeName, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
        (index > 0)
          ? (_openBlock(), _createBlock(AppIcon, {
              key: 0,
              class: "app-breadcrumbs__separator",
              name: "chevron_right",
              size: "18px"
            }))
          : _createCommentVNode("", true),
        _createElementVNode("h1", {
          class: _normalizeClass([
          'app-breadcrumbs__item',
          { 'app-breadcrumbs__item--last-route': isNotLastRoute(routeName) },
        ]),
          "data-testid": "organizations-page-header"
        }, _toDisplayString(_unref(t)(`nav.${routeName?.toString()}`)), 3)
      ], 64))
    }), 128)),
    (_unref(slots).trailing)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "trailing")
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})