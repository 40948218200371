<script setup lang="ts">
import { getFullNameInitials } from '@/plugins/commons';
import ProfileAvatar from '@/components/profile/ProfileAvatar/ProfileAvatar.vue';
import { WHITE } from '@/shared/constants/colors';

interface Props {
  name: string;
}

const props = defineProps<Props>();
</script>
<template>
  <div class="app-sidebar-name">
    <ProfileAvatar
      :name-initials="getFullNameInitials(props.name)"
      size="56px"
      :background-color="WHITE"
    />
    <h3>
      <b>{{ name }}</b>
    </h3>
  </div>
</template>
<style scoped lang="scss">
.app-sidebar-name {
  display: flex;
  padding: 0 16px;
  align-items: center;
  gap: 16px;
  background-color: $gray-50;
  border-radius: 8px;
}

.app-sidebar-name h3 {
  overflow: hidden;
  color: $gray-800;
  text-overflow: ellipsis;
  font-size: 18px;
}
</style>
