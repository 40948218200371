import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "app-button-dropdown__label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_btn_dropdown = _resolveComponent("q-btn-dropdown")!

  return (_openBlock(), _createBlock(_component_q_btn_dropdown, _mergeProps({
    class: ["app-button-dropdown", [
      `app-button-dropdown--${_ctx.type}`,
      {
        'app-button-dropdown--no-bg': _ctx.noBackground,
        'app-button-dropdown--not-rounded': !_ctx.rounded,
      },
    ]],
    "content-class": "app-button-dropdown__menu"
  }, _ctx.$props, {
    unelevated: "",
    "no-caps": "",
    rounded: "",
    ripple: false,
    "dropdown-icon": _ctx.dropdownIcon,
    "data-testid": "app-button-dropdown"
  }), {
    label: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "label", {}, undefined, true)
      ])
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _: 3
  }, 16, ["class", "dropdown-icon"]))
}