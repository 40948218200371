import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "app-form-section" }
const _hoisted_2 = {
  class: "app-form-section__title",
  "data-testid": "app-form-section-title"
}
const _hoisted_3 = {
  key: 0,
  class: "app-form-section__description",
  "data-testid": "app-form-section-description"
}

interface Props {
  title: string;
  description: string;
  showDescription?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppFormSection',
  props: {
    title: {},
    description: {},
    showDescription: { type: Boolean }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h6", _hoisted_2, _toDisplayString(_ctx.title), 1),
    (_ctx.showDescription)
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.description), 1))
      : _createCommentVNode("", true)
  ]))
}
}

})