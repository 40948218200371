import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "app-drawer-link-list" }

import { useI18n } from 'vue-i18n';
import { DrawerLink } from '@/shared/types/components';
import AppDrawerLink from '../AppDrawerLink/AppDrawerLink.vue';

interface Props {
  mini: boolean;
  links: DrawerLink[];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppDrawerLinkList',
  props: {
    mini: { type: Boolean },
    links: {}
  },
  setup(__props: any) {



const { t } = useI18n();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (link, i) => {
      return (_openBlock(), _createBlock(AppDrawerLink, {
        key: i,
        mini: _ctx.mini,
        to: link.to,
        label: _unref(t)(link.label)
      }, {
        leading: _withCtx(() => [
          (_openBlock(), _createBlock(_resolveDynamicComponent(link.icon)))
        ]),
        _: 2
      }, 1032, ["mini", "to", "label"]))
    }), 128))
  ]))
}
}

})