import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "app-list-selector" }
const _hoisted_2 = { class: "app-list-selector__list__body" }
const _hoisted_3 = { class: "app-list-selector__list__body__options" }
const _hoisted_4 = ["onKeydown", "onClick"]
const _hoisted_5 = { class: "app-list-selector__list__body__options__item__label" }
const _hoisted_6 = { class: "app-list-selector__buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppInput = _resolveComponent("AppInput")!
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([_ctx.leftListInformation, _ctx.rightListInformation], (list, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: 'list-' + index,
        class: "app-list-selector__list"
      }, [
        _renderSlot(_ctx.$slots, list.name + '-list-title', {}, undefined, true),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_AppInput, {
            modelValue: _ctx.searchModels[list.name],
            "onUpdate:modelValue": ($event: any) => ((_ctx.searchModels[list.name]) = $event),
            class: "app-list-selector__list__body__search-bar",
            clearable: "",
            "hide-bottom-space": "",
            placeholder: _ctx.$t('common.search.placeholder'),
            "format-numbers": false,
            "clear-icon": _ctx.mdiClose
          }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder", "clear-icon"]),
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list.options, (option) => {
              return (_openBlock(), _createElementBlock("div", {
                key: `${option[_ctx.optionValueKey]}`,
                class: _normalizeClass([
              'app-list-selector__list__body__options__item',
              {
                'app-list-selector__list__body__options__item--selected':
                  _ctx.selectedOptionValuesToOptionsMap[`${option[_ctx.optionValueKey]}`],
              },
            ]),
                onKeydown: _withModifiers(($event: any) => (_ctx.handleOptionClick(option)), ["exact"]),
                onClick: [
                  _withModifiers(($event: any) => (_ctx.handleOptionClick(option)), ["exact"]),
                  _withModifiers(($event: any) => (_ctx.handleOptionClick(option, true)), ["ctrl"]),
                  _withModifiers(($event: any) => (_ctx.handleOptionClick(option, true)), ["meta"])
                ]
              }, [
                _createElementVNode("span", _hoisted_5, _toDisplayString(option[_ctx.optionValueKey]), 1)
              ], 42, _hoisted_4))
            }), 128))
          ])
        ])
      ]))
    }), 128)),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_AppButton, {
        "fit-content": "",
        type: "empty",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (
          _ctx.moveSelectedOptions(
            _ctx.AppListSelectorListSide.LEFT,
            _ctx.AppListSelectorListSide.RIGHT,
          )
        ))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AppIcon, {
            size: "24px",
            class: "app-list-selector__buttons__left-icon",
            name: 
            _ctx.buttonsInformation[_ctx.orientation][_ctx.AppListSelectorListSide.LEFT].icon
          
          }, null, 8, ["name"])
        ]),
        _: 1
      }),
      _createVNode(_component_AppButton, {
        "fit-content": "",
        type: "empty",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (
          _ctx.moveSelectedOptions(
            _ctx.AppListSelectorListSide.RIGHT,
            _ctx.AppListSelectorListSide.LEFT,
          )
        ))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AppIcon, {
            size: "24px",
            class: "app-list-selector__buttons__right-icon",
            name: 
            _ctx.buttonsInformation[_ctx.orientation][_ctx.AppListSelectorListSide.RIGHT].icon
          
          }, null, 8, ["name"])
        ]),
        _: 1
      })
    ])
  ]))
}