import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { useI18n } from 'vue-i18n';
import IconEdit from '@/assets/icons/icon-edit.vue';
import AppButton from '../AppButton/AppButton.vue';

interface Props {
  iconSize?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppEditButton',
  props: {
    iconSize: { default: '16px' }
  },
  setup(__props: any) {

_useCssVars(_ctx => ({
  "73289b76": (_ctx.iconSize)
}))



const { t } = useI18n();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(AppButton, {
    size: "M",
    type: "bordered",
    label: _unref(t)('common.edit')
  }, {
    prepend: _withCtx(() => [
      _createVNode(IconEdit, { class: "app-edit-button__icon" })
    ]),
    _: 1
  }, 8, ["label"]))
}
}

})