import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-change-password-form" }
const _hoisted_2 = { class: "app-change-password-form__title" }
const _hoisted_3 = { class: "app-change-password-form__field" }
const _hoisted_4 = { class: "app-change-password-form__field" }
const _hoisted_5 = { class: "app-change-password-form__footer" }

import notify from '@/shared/helpers/notify/notify';
import {
  validateConfirmPassword,
  validateNewPassword,
} from '@/shared/helpers/password/changePasswordValidator/changePasswordValidator';
import { ResponseError } from '@/shared/types/utils';
import useProfileStore from '@/store/profile/useProfileStore';
import useUserStore from '@/store/user/useUserStore';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import useLogout from '@/composables/useLogOut/useLogOut';
import AppButton from '../AppButton/AppButton.vue';
import AppLabel from '../AppLabel/AppLabel.vue';
import AppPasswordInput from '../AppPasswordInput/AppPasswordInput.vue';
import AppPasswordValidations from '../AppPasswordValidations/AppPasswordValidations.vue';

interface Props {
  successRoute?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppChangePasswordForm',
  props: {
    successRoute: { default: '/account-settings/security' }
  },
  emits: ['password-changed'],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const { t } = useI18n();

const router = useRouter();

const userStore = useUserStore();

const profileStore = useProfileStore();

const { logOut } = useLogout();

const isLoading = ref<boolean>(false);

const newPasswordModel = ref<string>('');

const newPasswordConfirmationModel = ref<string>('');

const newPasswordError = ref<string>('');

const newPasswordConfirmationError = ref<string>('');

const validationsAreDisplayed = ref<boolean>(false);

function handleNewPasswordInputFocus(): void {
  validationsAreDisplayed.value = true;
}

function handleCancelClick(): void {
  router.back();
}

async function handleSaveClick(): Promise<void> {
  isLoading.value = false;

  newPasswordError.value = validateNewPassword(newPasswordModel.value);

  newPasswordConfirmationError.value = validateConfirmPassword(
    newPasswordModel.value,
    newPasswordConfirmationModel.value,
  );

  if (newPasswordError.value || newPasswordConfirmationError.value) {
    isLoading.value = false;

    return;
  }

  try {
    if (profileStore.userProfile?.user) {
      userStore.setUserEmail(profileStore.userProfile.user.email);
    }

    await userStore.changePassword(newPasswordModel.value);

    await router.push(props.successRoute);

    emit('password-changed');

    notify(t('changePassword.success'), 'success');
    await logOut();
  } catch (error) {
    if (error instanceof ResponseError) {
      notify(t(`errors.reason.${error.reason.code}`), 'danger');
    }
  } finally {
    isLoading.value = false;
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, [
      _renderSlot(_ctx.$slots, "title", {}, () => [
        _createTextVNode(_toDisplayString(_unref(t)('changePassword.header.createPassword')), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(AppLabel, {
        label: _unref(t)('changePassword.newPassword'),
        for: "new-password"
      }, null, 8, ["label"]),
      _createVNode(AppPasswordInput, {
        modelValue: newPasswordModel.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((newPasswordModel).value = $event)),
        name: "new-password",
        "data-testid": "app-change-password-form-new-password-field",
        error: newPasswordError.value,
        onFocus: handleNewPasswordInputFocus
      }, null, 8, ["modelValue", "error"])
    ]),
    (validationsAreDisplayed.value)
      ? (_openBlock(), _createBlock(AppPasswordValidations, {
          key: 0,
          class: "app-change-password-form__validations",
          password: newPasswordModel.value
        }, null, 8, ["password"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(AppLabel, {
        label: _unref(t)('changePassword.confirmPassword'),
        for: "new-password-confirmation"
      }, null, 8, ["label"]),
      _createVNode(AppPasswordInput, {
        modelValue: newPasswordConfirmationModel.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((newPasswordConfirmationModel).value = $event)),
        name: "new-password-confirmation",
        error: newPasswordConfirmationError.value
      }, null, 8, ["modelValue", "error"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _renderSlot(_ctx.$slots, "actions", {
        submit: handleSaveClick,
        isLoading: isLoading.value
      }, () => [
        _createVNode(AppButton, {
          type: "empty",
          loading: isLoading.value,
          label: _unref(t)('labels.common.cancel'),
          onClick: handleCancelClick
        }, null, 8, ["loading", "label"]),
        _createVNode(AppButton, {
          type: "bordered",
          loading: isLoading.value,
          label: _unref(t)('changePassword.updatePassword'),
          onClick: handleSaveClick
        }, null, 8, ["loading", "label"])
      ])
    ])
  ]))
}
}

})