import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "app-sidebar-header" }
const _hoisted_2 = { class: "app-sidebar-header__title" }
const _hoisted_3 = {
  key: 0,
  class: "app-sidebar-header__actions"
}
const _hoisted_4 = {
  key: 1,
  class: "app-sidebar-header__close"
}

import { computed, getCurrentInstance } from 'vue';
import IconClear from '@/assets/icons/icon-clear.vue';
import { BLUE_500 } from '@/shared/constants/colors';
import { SidebarActionList } from '@/shared/types/components';
import { useI18n } from 'vue-i18n';
import { BaseTooltip } from '@/plugins/commons';
import AppSeparator from '../../AppSeparator/AppSeparator.vue';
import AppButton from '../../AppButton/AppButton.vue';
import AppSidebarActions from '../../AppSidebarActions/AppSidebarActions.vue';

interface Props {
  title: string;
  actions?: SidebarActionList;
  editMode: boolean;
}

interface Emits {
  (e: 'close'): void;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppSidebarHeader',
  props: {
    title: { default: '' },
    actions: { default: undefined },
    editMode: { type: Boolean, default: true }
  },
  emits: ["close"],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const props = __props;

const emit = __emit;

const { t } = useI18n();

const hasCloseEmit = computed<boolean>(
  () => !!getCurrentInstance()?.vnode.props?.onClose,
);

const title = computed<string>(() => {
  if (props.editMode) {
    return t('sidebars.title.edit', [props.title]);
  }

  return props.title;
});

__expose({
  hasCloseEmit,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", _hoisted_2, [
      _createElementVNode("b", null, _toDisplayString(title.value), 1)
    ]),
    (!_ctx.editMode)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.actions)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(AppSidebarActions, { list: _ctx.actions }, null, 8, ["list"])
              ]))
            : _createCommentVNode("", true),
          (hasCloseEmit.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(AppSeparator, {
                  vertical: "",
                  inset: ""
                }),
                _createVNode(AppButton, {
                  class: "app-sidebar-header__close__button",
                  type: "icon",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('close')))
                }, {
                  default: _withCtx(() => [
                    _createVNode(IconClear, {
                      width: "16",
                      height: "16",
                      color: _unref(BLUE_500)
                    }, null, 8, ["color"]),
                    _createVNode(_unref(BaseTooltip), {
                      anchor: "top middle",
                      self: "bottom middle",
                      offset: [5, 5]
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(t)('common.close')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true)
  ]))
}
}

})