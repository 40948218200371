<script setup lang="ts">
import ProfileAvatar from '@/components/profile/ProfileAvatar/ProfileAvatar.vue';
import { getFullNameInitials } from '@vaibe-github-enterprise/kd-kse-fe-commons';

interface Props {
  name: string;
}

const props = defineProps<Props>();
</script>

<template>
  <div class="app-table-name-column">
    <ProfileAvatar
      size="32px"
      label-font-size="12px"
      :name-initials="getFullNameInitials(props.name)"
    />
    <p
      class="app-table-name-column__label"
      data-testid="app-table-name-column-label"
    >
      {{ name }}
    </p>
  </div>
</template>

<style scoped lang="scss">
.app-table-name-column {
  gap: 8px;
  display: flex;
  align-items: center;
}

.app-table-name-column__label {
  margin: 0;
  font-size: 14px;
}
</style>
