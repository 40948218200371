import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "app-sidebar__content" }

import {
  onMounted,
  onUnmounted,
  shallowRef,
  ComponentPublicInstance,
} from 'vue';
import { SidebarActionList } from '@/shared/types/components';
import AppSidebarHeader from './AppSidebarHeader/AppSidebarHeader.vue';

interface Props {
  sidebarTitle: string;
  actions?: SidebarActionList;
  editMode: boolean;
}

interface Emit {
  (e: 'clickOutside'): void;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppSidebar',
  props: /*@__PURE__*/_mergeModels({
    sidebarTitle: {},
    actions: { default: undefined },
    editMode: { type: Boolean, default: false }
  }, {
    "modelValue": { type: Boolean },
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(["clickOutside"], ["update:modelValue"]),
  setup(__props: any, { emit: __emit }) {

const appSidebarOpen = _useModel<boolean>(__props, "modelValue");

const appSidebar = shallowRef<ComponentPublicInstance | null>(null);



const emit = __emit;

const handleMouseDown = (event: MouseEvent) => {
  if (appSidebarOpen.value) {
    const target = event.target as HTMLElement;

    const appSidebarElement = appSidebar.value?.$el;

    const documentPositionComparison =
      target.compareDocumentPosition(appSidebarElement);

    if (
      documentPositionComparison !== Node.DOCUMENT_POSITION_PRECEDING &&
      documentPositionComparison !== Node.DOCUMENT_TYPE_NODE
    ) {
      emit('clickOutside');
    }
  }
};

const closeSidebar = () => {
  appSidebarOpen.value = false;
};

onMounted(() => {
  window.addEventListener('mousedown', handleMouseDown);
});

onUnmounted(() => {
  window.removeEventListener('mousedown', handleMouseDown);
});

return (_ctx: any,_cache: any) => {
  const _component_q_drawer = _resolveComponent("q-drawer")!

  return (_openBlock(), _createBlock(_component_q_drawer, _mergeProps({
    ref_key: "appSidebar",
    ref: appSidebar
  }, _ctx.$props, {
    modelValue: appSidebarOpen.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((appSidebarOpen).value = $event)),
    side: "right",
    class: "app-sidebar",
    overlay: "",
    "data-testid": "app-sidebar-drawer",
    behavior: "desktop",
    persistent: "",
    width: 568
  }), {
    default: _withCtx(() => [
      _createVNode(AppSidebarHeader, {
        title: _ctx.sidebarTitle,
        "edit-mode": _ctx.editMode,
        actions: _ctx.actions,
        onClose: closeSidebar
      }, null, 8, ["title", "edit-mode", "actions"]),
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ]),
    _: 3
  }, 16, ["modelValue"]))
}
}

})