import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

interface Props {
  mini: boolean;
}

const APP_DRAWER_WIDTH = 257;

const APP_DRAWER_MINI_WIDTH = 64;


export default /*@__PURE__*/_defineComponent({
  __name: 'AppDrawer',
  props: /*@__PURE__*/_mergeModels({
    mini: { type: Boolean }
  }, {
    "modelValue": { type: Boolean, ...{ required: true } },
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {



const model = _useModel<boolean>(__props, "modelValue");

return (_ctx: any,_cache: any) => {
  const _component_q_drawer = _resolveComponent("q-drawer")!

  return (_openBlock(), _createBlock(_component_q_drawer, {
    modelValue: model.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((model).value = $event)),
    mini: _ctx.mini,
    breakpoint: 0,
    width: APP_DRAWER_WIDTH,
    "mini-width": APP_DRAWER_MINI_WIDTH,
    "show-if-above": ""
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["modelValue", "mini"]))
}
}

})