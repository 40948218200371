import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  "data-testid": "app-password-validations-item-label",
  class: "app-password-validations-item__label"
}

import IconSuccess from '@icons/icon-success.vue';

interface Props {
  label: string;
  isValid: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppPasswordValidationsItem',
  props: {
    label: {},
    isValid: { type: Boolean }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    "data-testid": "app-password-validations-item",
    class: _normalizeClass([
      'app-password-validations-item',
      { 'app-password-validations-item--valid': _ctx.isValid },
    ])
  }, [
    _createVNode(IconSuccess, { class: "app-password-validations-item__icon" }),
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.label), 1)
  ], 2))
}
}

})