import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "app-tabs__tab__count",
  "data-testid": "app-tabs-tab-count"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppSeparator = _resolveComponent("AppSeparator")!
  const _component_q_route_tab = _resolveComponent("q-route-tab")!
  const _component_q_tabs = _resolveComponent("q-tabs")!

  return (_openBlock(), _createBlock(_component_q_tabs, _mergeProps(_ctx.$attrs, {
    class: "app-tabs",
    "data-testid": "app-tabs"
  }), {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
        return (_openBlock(), _createElementBlock("div", {
          key: tab.to,
          class: "app-tabs__tab",
          "data-testid": "app-tabs-tab"
        }, [
          (tab.separator)
            ? (_openBlock(), _createBlock(_component_AppSeparator, {
                key: 0,
                vertical: "",
                inset: "",
                class: "app-tabs__separator"
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_q_route_tab, {
            to: tab.to,
            ripple: false,
            label: tab.label,
            exact: tab?.exact,
            "no-caps": "",
            "data-testid": "app-tabs-tab-route",
            class: _normalizeClass([{ 'q-tab--active': tab.active }]),
            onClick: tab?.onClick
          }, null, 8, ["to", "label", "exact", "class", "onClick"]),
          (tab?.count)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(tab?.count), 1))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ]),
    _: 1
  }, 16))
}