import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "app-label" }
const _hoisted_2 = {
  key: 0,
  class: "app-label__label--optional"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppHint = _resolveComponent("AppHint")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", {
      "data-testid": "app-label__label",
      class: _normalizeClass([
        'app-label__label',
        `app-label__label--${_ctx.size}`,
        { 'app-label__label--required': _ctx.isRequired },
      ])
    }, [
      _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
      (_ctx.isOptional)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t('labels.common.optional')), 1))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.information)
      ? (_openBlock(), _createBlock(_component_AppHint, {
          key: 0,
          hint: _ctx.information,
          "max-tooltip-width": _ctx.maxTooltipWidth
        }, null, 8, ["hint", "max-tooltip-width"]))
      : _renderSlot(_ctx.$slots, "trailing", { key: 1 }, undefined, true)
  ]))
}