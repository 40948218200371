import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-empty-state" }
const _hoisted_2 = {
  class: "app-empty-state__container",
  "data-testid": "app-empty-state-container"
}
const _hoisted_3 = { class: "app-empty-state__image" }
const _hoisted_4 = {
  class: "app-empty-state__title",
  "data-testid": "app-empty-state-title"
}
const _hoisted_5 = {
  class: "app-empty-state__description",
  "data-testid": "app-empty-state-description"
}

import { type Component } from 'vue';
import EmptyStateMagnifying from '@icons/icon-empty-state-magnifying.vue';

interface Props {
  icon?: Component;
  imageHeight?: string;
  title: string;
  description?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppEmptyState',
  props: {
    icon: { default: undefined },
    imageHeight: { default: '220px' },
    title: { default: 'common.emptyState.title' },
    description: { default: 'common.emptyState.description' }
  },
  setup(__props: any) {

_useCssVars(_ctx => ({
  "15ed62a8": (_ctx.imageHeight)
}))



return (_ctx: any,_cache: any) => {
  const _component_i18n_t = _resolveComponent("i18n-t")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.icon)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icon), { key: 0 }))
          : (_openBlock(), _createBlock(EmptyStateMagnifying, { key: 1 }))
      ]),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t(_ctx.title)), 1),
      _renderSlot(_ctx.$slots, "description", {}, () => [
        _createElementVNode("p", _hoisted_5, [
          _createVNode(_component_i18n_t, {
            keypath: _ctx.description,
            tag: "p",
            scope: "global"
          }, {
            br: _withCtx(() => _cache[0] || (_cache[0] = [
              _createElementVNode("br", null, null, -1)
            ])),
            _: 1
          }, 8, ["keypath"])
        ])
      ])
    ]),
    _renderSlot(_ctx.$slots, "default")
  ]))
}
}

})