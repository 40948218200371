import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-form-footer" }

import AppButton from '../AppButton/AppButton.vue';

interface Props {
  formId?: string;
  isLoading: boolean;
}

interface Emits {
  (e: 'save'): void;
  (e: 'cancel'): void;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppFormFooter',
  props: {
    formId: {},
    isLoading: { type: Boolean }
  },
  emits: ["save", "cancel"],
  setup(__props: any, { emit: __emit }) {



const emit = __emit;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(AppButton, {
      type: "empty",
      size: "M",
      "data-testid": "cancel-button",
      label: _ctx.$t('labels.common.cancel'),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('cancel')))
    }, null, 8, ["label"]),
    _createVNode(AppButton, {
      type: "vaibe",
      "native-type": "submit",
      size: "M",
      "data-testid": "save-button",
      form: _ctx.formId,
      loading: _ctx.isLoading,
      label: _ctx.$t('labels.common.save'),
      onClick: _cache[1] || (_cache[1] = ($event: any) => (emit('save')))
    }, null, 8, ["form", "loading", "label"])
  ]))
}
}

})