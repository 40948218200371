import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-hint" }

import { matInfo } from '@quasar/extras/material-icons';
import { BaseTooltip } from '@/plugins/commons';
import AppIcon from '../AppIcon/AppIcon.vue';

interface Props {
  hint: string;
  size?: string;
  maxTooltipWidth?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppHint',
  props: {
    hint: {},
    size: { default: '12px' },
    maxTooltipWidth: { default: '256px' }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(BaseTooltip), { "max-width": _ctx.maxTooltipWidth }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.hint), 1)
      ]),
      _: 1
    }, 8, ["max-width"]),
    _createVNode(AppIcon, {
      size: _ctx.size,
      name: _unref(matInfo)
    }, null, 8, ["size", "name"])
  ]))
}
}

})