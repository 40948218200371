import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import AppBadge from '@/components/app/AppBadge/AppBadge.vue';
import getStatusBadgeColor from '@/shared/helpers/getStatusBadgeColor/getStatusBadgeColor';
import { StatusStaticReference } from '@/shared/types/generic';
import { useI18n } from 'vue-i18n';

interface Props {
  status: StatusStaticReference;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppTableStatusColumn',
  props: {
    status: {}
  },
  setup(__props: any) {



const { t } = useI18n();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(AppBadge, {
    height: "32px",
    padding: "4px 16px",
    class: "app-table-status-column",
    color: _unref(getStatusBadgeColor)(_ctx.status)
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_unref(t)(`enums.status.${_ctx.status}`)), 1)
    ]),
    _: 1
  }, 8, ["color"]))
}
}

})