import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "app-error-message",
  "data-testid": "app-error-message"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "app-error-message__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.error)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("img", {
          src: _ctx.errorIcon,
          alt: "Error",
          class: "app-error-message__icon"
        }, null, 8, _hoisted_2),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.error), 1)
      ]))
    : _createCommentVNode("", true)
}