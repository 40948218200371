import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "app-layout-drawer__content" }
const _hoisted_2 = { class: "app-layout-drawer__content__bottom" }

import ProfileDrawerLink from '@/components/profile/ProfileDrawerLink/ProfileDrawerLink.vue';
import useDrawerLinks from '@/composables/useDrawerLinks/useDrawerLinks';
import OrganizationSettingsLink from '@/components/organizations/OrganizationSettingsLink/OrganizationSettingsLink.vue';
import AppDrawerLinkList from '../AppDrawerLinkList/AppDrawerLinkList.vue';
import AppVaibeLogo from '../AppVaibeLogo/AppVaibeLogo.vue';
import AppDrawer from '../AppDrawer/AppDrawer.vue';

interface Props {
  mini: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppLayoutDrawer',
  props: /*@__PURE__*/_mergeModels({
    mini: { type: Boolean }
  }, {
    "modelValue": { type: Boolean, ...{ required: true } },
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {



const model = _useModel<boolean>(__props, "modelValue");

const { drawerLinks } = useDrawerLinks();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(AppDrawer, {
    modelValue: model.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((model).value = $event)),
    mini: _ctx.mini,
    class: _normalizeClass(['app-layout-drawer', { 'app-layout-drawer--mini': _ctx.mini }])
  }, {
    default: _withCtx(() => [
      _createVNode(AppVaibeLogo, {
        class: "app-layout-drawer__logo",
        "is-small": _ctx.mini
      }, null, 8, ["is-small"]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(AppDrawerLinkList, {
          class: "app-layout-drawer__content__top",
          mini: _ctx.mini,
          links: _unref(drawerLinks)
        }, null, 8, ["mini", "links"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(OrganizationSettingsLink, { mini: _ctx.mini }, null, 8, ["mini"]),
          _createVNode(ProfileDrawerLink, { mini: _ctx.mini }, null, 8, ["mini"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue", "mini", "class"]))
}
}

})