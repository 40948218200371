import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  "data-testid": "app-drawer-link-leading",
  class: "app-drawer-link__leading"
}
const _hoisted_2 = {
  "data-testid": "app-drawer-link-label",
  class: "app-drawer-link__label"
}

interface Props {
  to: string;
  mini: boolean;
  label: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppDrawerLink',
  props: {
    to: {},
    mini: { type: Boolean },
    label: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: _ctx.to,
    class: _normalizeClass(['app-drawer-link', { 'app-drawer-link--mini': _ctx.mini }]),
    "active-class": "app-drawer-link--active"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "leading")
      ]),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.label), 1)
    ]),
    _: 3
  }, 8, ["to", "class"]))
}
}

})