import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import AppInput from '@/components/app/AppInput/AppInput.vue';
import AppPasswordIcon from '@/components/app/AppPasswordIcon/AppPasswordIcon.vue';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

interface Props {
  error: string;
  placeholder?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppPasswordInput',
  props: /*@__PURE__*/_mergeModels({
    error: {},
    placeholder: { default: 'placeholders.login.password' }
  }, {
    "modelValue": {},
    "modelModifiers": {},
  }),
  emits: ["update:modelValue"],
  setup(__props: any) {

const { t } = useI18n();

const props = __props;

const model = _useModel<string>(__props, "modelValue");

const passwordIsShown = ref<boolean>(false);

const inputType = computed<'text' | 'password'>(() => {
  if (passwordIsShown.value) {
    return 'text';
  }

  return 'password';
});

const isError = computed<boolean>(() => props.error.length > 0);

function togglePasswordVisibility(): void {
  passwordIsShown.value = !passwordIsShown.value;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(AppInput, {
    modelValue: model.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((model).value = $event)),
    dense: false,
    error: _ctx.error,
    type: inputType.value,
    "format-numbers": false,
    placeholder: _unref(t)(_ctx.placeholder)
  }, {
    append: _withCtx(() => [
      _createVNode(AppPasswordIcon, {
        "password-has-errors": isError.value,
        "show-password": passwordIsShown.value,
        onClick: togglePasswordVisibility
      }, null, 8, ["password-has-errors", "show-password"])
    ]),
    _: 1
  }, 8, ["modelValue", "error", "type", "placeholder"]))
}
}

})