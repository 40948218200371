import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-sidebar-name" }

import { getFullNameInitials } from '@/plugins/commons';
import ProfileAvatar from '@/components/profile/ProfileAvatar/ProfileAvatar.vue';
import { WHITE } from '@/shared/constants/colors';

interface Props {
  name: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppSidebarName',
  props: {
    name: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(ProfileAvatar, {
      "name-initials": _unref(getFullNameInitials)(props.name),
      size: "56px",
      "background-color": _unref(WHITE)
    }, null, 8, ["name-initials", "background-color"]),
    _createElementVNode("h3", null, [
      _createElementVNode("b", null, _toDisplayString(_ctx.name), 1)
    ])
  ]))
}
}

})