import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-password-validations" }

import {
  STRING_HAS_AT_LEAST_ONE_NUMBER_AND_SPECIAL_CHARACTER,
  STRING_HAS_AT_LEAST_ONE_UPPERCASE_AND_LOWERCASE_LETTER,
  STRING_HAS_BETWEEN_12_AND_64_CHARACTERS,
  STRING_HAS_NO_REPEATED_CONSECUTIVE_CHARACTERS,
} from '@/shared/constants/regularExpressions';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import AppPasswordValidationsItem from '../AppPasswordValidationsItem/AppPasswordValidationsItem.vue';

interface Props {
  password: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AppPasswordValidations',
  props: {
    password: {}
  },
  setup(__props: any) {

const props = __props;

const passwordHasAtLeast12Characters = computed<boolean>(() =>
  STRING_HAS_BETWEEN_12_AND_64_CHARACTERS.test(props.password),
);

const passwordHasAtLeastOneUppercaseAndLowercaseLetter = computed<boolean>(() =>
  STRING_HAS_AT_LEAST_ONE_UPPERCASE_AND_LOWERCASE_LETTER.test(props.password),
);

const passwordHasAtLeastOneNumberAndSpecialCharacter = computed<boolean>(() =>
  STRING_HAS_AT_LEAST_ONE_NUMBER_AND_SPECIAL_CHARACTER.test(props.password),
);

const passwordHasNoRepeatedConsecutiveCharacters = computed<boolean>(() =>
  STRING_HAS_NO_REPEATED_CONSECUTIVE_CHARACTERS.test(props.password),
);

const { t } = useI18n();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("b", null, [
      _createElementVNode("span", null, _toDisplayString(_unref(t)('changePassword.mustContain')), 1)
    ]),
    _createVNode(AppPasswordValidationsItem, {
      label: _unref(t)('changePassword.validations.numberOfCharacters'),
      "is-valid": passwordHasAtLeast12Characters.value
    }, null, 8, ["label", "is-valid"]),
    _createVNode(AppPasswordValidationsItem, {
      label: _unref(t)('changePassword.validations.lettersCombination'),
      "is-valid": passwordHasAtLeastOneUppercaseAndLowercaseLetter.value
    }, null, 8, ["label", "is-valid"]),
    _createVNode(AppPasswordValidationsItem, {
      label: _unref(t)('changePassword.validations.specialCharacters'),
      "is-valid": passwordHasAtLeastOneNumberAndSpecialCharacter.value
    }, null, 8, ["label", "is-valid"]),
    _createVNode(AppPasswordValidationsItem, {
      label: _unref(t)('changePassword.validations.consecutiveCharacters'),
      "is-valid": passwordHasNoRepeatedConsecutiveCharacters.value
    }, null, 8, ["label", "is-valid"])
  ]))
}
}

})